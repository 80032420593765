import { detect } from "detect-browser";
import EventEmitter from "events";
import isNil from "lodash/isNil";
import CLIENT_TYPE, { getTokenKeyForClientType } from "~~/constants/clientType";
import StageSubLevel from "~~/constants/stageSubLevel";
import VIDEO_CONFIGURATION from "~~/constants/videoConfiguration";
import * as actions from "~~/redux/actions/video";
import {
  addVideoCallStream,
  clearVideoCallStreams,
  removeVideoCallStreamById,
} from "~~/redux/actions/videoCallStreams";
import {
  getNumGreenRoomStreams,
  getOnStageStreamIds,
} from "~~/redux/selectors/video";
import store from "~~/redux/store";
import { fetchReg } from "~~/services/registrationService";
import axios from "~~/utils/authenticatedAxios";
import { getFeatureFlags } from "~~/utils/featureFlags";
import {
  getRegIdFromStreamId,
  getStreamIdFromUserId,
  isScreenshare,
  isVideoMuted,
} from "~~/utils/streamUtils";
import WelcomeAV from "~~/welcomeav";
import DummyRCStream from "~~/welcomeav/streaming/models/dummyRcStream";
import WLog from "~~/wlog";
import { applyVirtualBackgroundToRCStream } from "~~/services/virtualBackgroundService";

// Enable web logs
if (typeof AgoraRTC !== "undefined") {
  const logLevel = window.CLIENT_ENV.NODE_ENV === "development" ? 0 : 2;
  AgoraRTC.enableLogUpload();
  AgoraRTC.setLogLevel(logLevel);
}

const PREFERRED_DEVICES_KEY = "preferredDevices";
const GR_VOLUME_KEY = "greenRoomVolume";
const GR_PREMUTE_VOLUME_KEY = "greenRoomPreMuteVolume";
const DEFAULT_GR_VOLUME = 50;
const STAGE_VOLUME_KEY = "stageVolume";
const STAGE_PREMUTE_VOLUME_KEY = "stagePreMuteVolume";
export const DEFAULT_STAGE_VOLUME = 50;
const DEFAULT_VIDEO_CALL_VOLUME = 50;
export const RETRY_WITH_PROXY_TIMEOUT = 8000;
const emitter = new EventEmitter();
// TODO: Phase out event emitter. Setting max listeners to a high
// value to prevent issues w/ reaching the maximum
emitter.setMaxListeners(30);
let poorConnectionMode = false;
let isUsingProxy = false;

const events = [
  "tracksReplaced",
  "streamMuteChanged",
  "breakoutActiveSpeaker",
  "stageStreamSubscribed",
  "newDevicesConnected",
  "devicesDisconnected",
];

export const ERRORS = {
  NO_TRACKS: "NO_TRACKS",
  STREAM_LIMIT_REACHED: "STREAM_LIMIT_REACHED",
};

// Agora's fixed key
export const RTMP_STREAM_KEY = 666;

// File globals
let lastFetchedDevices = null;

export const MAX_GREEN_ROOM_STREAMS = 12;

/*
=======================================================================================================
UTILITY METHODS ?
Idk, these are public but private methods depend on it so I wasn't so sure where to put it.
=======================================================================================================
*/
export async function getDevices(noCamera = false, useBrowserApi = false) {
  // Only intended to be used for testing purposes.
  if (useBrowserApi) {
    return navigator.mediaDevices.enumerateDevices();
  }

  const devices = await WelcomeAV.getDevices(noCamera);
  lastFetchedDevices = devices;
  return devices;
}

/**
 * Allows hard-coding of poor connection mode setting
 *
 * @param {Boolean} pcm Whether or not we're in poor connection mode.
 */
export function setPoorConnectionMode(pcm) {
  poorConnectionMode = pcm;
}

/**
 * Watch for devices being connected/removed.
 *
 * NOTE: This will trigger once per device connection. If you connect a webcam that has both
 * a camera and a microphone, it will trigger twice. The code is a little overly defensive &
 * doesn't reflect that.
 * */
if (navigator && navigator.mediaDevices) {
  navigator.mediaDevices.ondevicechange = () => {
    if (lastFetchedDevices == null) {
      return;
    }

    const prevDevices = [...lastFetchedDevices];

    getDevices().then((devices) => {
      const newDevices = devices.filter(
        (device) => !prevDevices.find((d) => d.deviceId === device.deviceId)
      );

      if (newDevices.length > 0) {
        const device = newDevices[0] || {};
        emitter.emit("newDevicesConnected", device.label);
      }

      const removedDevices = prevDevices.filter((device) => {
        return !devices.find((d) => d.deviceId === device.deviceId);
      });

      if (removedDevices.length > 0) {
        emitter.emit("devicesDisconnected", removedDevices);
      }
    });
  };
}

export function maxGreenRoomStreamsReached() {
  return (
    getNumGreenRoomStreams(store.getState()).length >= MAX_GREEN_ROOM_STREAMS
  );
}

export function isUserStreamingVideo() {
  const {
    Video: { localClients },
  } = store.getState();

  const hasPrimaryStream =
    !!localClients[CLIENT_TYPE.PRIMARY] &&
    !!localClients[CLIENT_TYPE.PRIMARY].stream;
  const hasSecondaryStream =
    !!localClients[CLIENT_TYPE.SECONDARY] &&
    !!localClients[CLIENT_TYPE.SECONDARY].stream;

  return hasPrimaryStream || hasSecondaryStream;
}

export function doesPrimaryStreamExist() {
  const {
    Video: {
      localClients: {
        [CLIENT_TYPE.PRIMARY]: { client: primaryClient, stream: primaryStream },
      },
    },
  } = store.getState();

  return primaryClient && primaryStream;
}

async function subscribe(client, stream) {
  return client.subscribe(stream);
}

export function isUserOnStage() {
  const { registration } = store.getState().User;
  return getOnStageStreamIds(store.getState()).includes(registration.id);
}

export function numberOfStreamsOnStage() {
  return getOnStageStreamIds(store.getState()).length;
}

export function isUserScreenSharing() {
  const { localClients } = store.getState().Video;
  return !!(
    localClients[CLIENT_TYPE.SCREEN] &&
    localClients[CLIENT_TYPE.SCREEN].stream !== null
  );
}

export function isScreenshareOnStage() {
  const {
    stageSnapshot: { stageScreenshareStreamId },
    streams,
  } = store.getState().Video;
  return (
    stageScreenshareStreamId !== null && stageScreenshareStreamId in streams
  );
}

export function isUserScreenshareOnStage() {
  const {
    Video: {
      stageSnapshot: { stageScreenshareStreamId },
      localClients: {
        [CLIENT_TYPE.SCREEN]: { stream: screenStream },
      },
    },
  } = store.getState();

  return (
    isScreenshareOnStage() &&
    isUserScreenSharing() &&
    stageScreenshareStreamId === screenStream.getId()
  );
}

/**
 * @returns Object with shape { cameraId, microphoneId, virtualBackgroundType }
 */
export function getPreferredAv() {
  const json = localStorage.getItem(PREFERRED_DEVICES_KEY);
  if (json) {
    return JSON.parse(json) || {};
  }
  return {};
}

/**
 * @param {Object} devices Object with shape { cameraId, microphoneId, virtualBackgroundType }
 */
export function setPreferredAv({
  cameraId,
  microphoneId,
  virtualBackgroundType,
}) {
  const curr = getPreferredAv();
  const updatedSettings = {
    ...curr,
    ...(cameraId !== undefined && { cameraId }),
    ...(microphoneId !== undefined && { microphoneId }),
    ...(virtualBackgroundType !== undefined && { virtualBackgroundType }),
  };
  localStorage.setItem(PREFERRED_DEVICES_KEY, JSON.stringify(updatedSettings));
}

export function isLocalStreamId(streamId) {
  // Def not the most performant way to go about this...
  const localStreams = Object.values(store.getState().Video.localClients)
    .map((c) => c.stream)
    .filter((x) => x);

  return !!localStreams.find((stream) => stream && stream.getId() === streamId);
}

export function isLocal(agoraStream) {
  return agoraStream.local;
}

/**
 * Helper function to only set video encoder configuration if its different from the current
 * config
 *
 * @param {*} stream stream to set
 * @param {*} newConfig video config for the stream
 */
async function setVideoEncoderConfiguration(stream, config) {
  if (stream === null) {
    return Promise.resolve();
  }

  /**
   * Two 4.x-specific changes here: (1) returning the promise and (2) passing
   * whether or not this is screenshare, since we have to handle the logic
   * differently.
   */
  return stream.setVideoEncoderConfiguration(config, isScreenshare(stream));
}

async function adjustVideoCallResolution() {
  const { VideoCallStreams: streamObj } = store.getState();
  const { localClients } = store.getState().Video;
  if (!localClients[CLIENT_TYPE.SECONDARY]) {
    // No-op if no secondary client
    return;
  }
  const clientData = localClients[CLIENT_TYPE.SECONDARY];

  const streams = Object.values(streamObj);
  if (!clientData || !clientData.client || !clientData.stream) {
    return;
  }

  if (streams.length > 9) {
    setVideoEncoderConfiguration(
      clientData.stream,
      VIDEO_CONFIGURATION.VERY_LOW_BREAKOUT
    );
  } else if (streams.length > 4) {
    setVideoEncoderConfiguration(
      clientData.stream,
      VIDEO_CONFIGURATION.LOW_BREAKOUT
    );
  } else {
    setVideoEncoderConfiguration(
      clientData.stream,
      VIDEO_CONFIGURATION.HIGH_BREAKOUT
    );
  }
}

/**
 * Gets the video configuration for a stream of the given client type,
 * given the current state of the stage/green room/video call (depending
 * on which client type is passed in)
 *
 * This is ONLY EXPORTED TO BE TESTED IN videoService.test.js... unfortunately
 */
export function getVideoConfigurationForClientType(clientType) {
  const {
    Video: {
      stageSnapshot: { onStageStreams, stageScreenshareStreamId },
      streams,
    },
    User: { registration },
  } = store.getState();

  if (!registration.id) {
    return null;
  }

  const streamId = getStreamIdFromUserId(registration.id, clientType);

  const stageScreenshareId =
    stageScreenshareStreamId && !!streams[stageScreenshareStreamId]
      ? stageScreenshareStreamId
      : null;

  if (clientType === CLIENT_TYPE.PRIMARY) {
    const numStreamsOnStage = onStageStreams.reduce(
      (count, onStageStreamId) => {
        if (streams[onStageStreamId]) {
          return count + 1;
        }
        return count;
      },
      0
    );

    const isStreamOnStage =
      onStageStreams.includes(streamId) && !!streams[streamId];

    if (
      isStreamOnStage &&
      numStreamsOnStage === 1 &&
      !stageScreenshareId &&
      !poorConnectionMode
    ) {
      return VIDEO_CONFIGURATION.HIGH_STAGE;
    }

    if (!isStreamOnStage) {
      return VIDEO_CONFIGURATION.LOW_STAGE;
    }

    return VIDEO_CONFIGURATION.MEDIUM_STAGE;
  }

  if (clientType === CLIENT_TYPE.SCREEN) {
    const highScreenshareResolution = VIDEO_CONFIGURATION.HIGHER_SCREENSHARE;
    return stageScreenshareStreamId === streamId
      ? highScreenshareResolution
      : VIDEO_CONFIGURATION.LOW_SCREENSHARE;
  }

  return null;
}

/*
=======================================================================================================
PRIVATE METHODS
=======================================================================================================
*/

function _addStream(stream) {
  store.dispatch(actions.addStream(stream));
}

function _removeStreamById(streamId) {
  store.dispatch(actions.removeStreamById(streamId));
}

function _addVideoCallStream(stream) {
  store.dispatch(addVideoCallStream(stream));
  adjustVideoCallResolution();
}

function _removeVideoCallStreamById(streamId) {
  store.dispatch(removeVideoCallStreamById(streamId));
  adjustVideoCallResolution();
}

function _getClient(clientType) {
  const { localClients } = store.getState().Video;
  return localClients[clientType] || {};
}

function _getLocalStream(id) {
  const { localClients } = store.getState().Video;
  const streams = Object.values(localClients)
    .map((c) => c.stream)
    .filter((s) => s);

  for (let i = 0; i < streams.length; i += 1) {
    if (streams[i].getId() === id) {
      return streams[i];
    }
  }

  return null;
}

function isOnStage(stream, snapshot) {
  const { onStageStreams, stageScreenshareStreamId } = snapshot;
  return (
    stageScreenshareStreamId === stream.getId() ||
    onStageStreams.includes(stream.getId()) ||
    stream.getId() === RTMP_STREAM_KEY
  );
}

async function _createStream(clientType, config, videoConfiguration) {
  const client = store.getState().Video.localClients[clientType]?.client;
  if (!client) {
    throw new Error("Attempting to create stream but client does not exist");
  }

  return WelcomeAV.createStream({
    client,
    camera: config.cameraId || config.video || false,
    microphone: config.microphoneId || config.audio || false,
    screen: config.screen || false,
    screenAudio: config.screen || false,
    videoEncoderConfiguration: videoConfiguration,
  });
}

/**
 * Creates a new stream, sets its video encoder configuration, and then
 * replaces the tracks of the given stream with the new stream's tracks
 */
async function setVideoEncoderConfigurationAndUpdateTracks(
  stream,
  videoConfig
) {
  try {
    await setVideoEncoderConfiguration(stream, videoConfig);
  } catch (error) {
    console.error(error);
  }
}

/**
 * This is exported for internal video testing *only*... do not use! Do not use!
 *
 * @param {number} clientType The type of the client to create
 * @param {Object} config Client config
 * @param {Object} joinConfig Config for joining a channel. Defaults to the main stage (stored in redux).
 * @param {Boolean} returnExisting Whether or not to return the client if it already exists. If false, this will throw
 * an error instead. Defaults to false.
 * @param {Boolean} isHost Is the user a host (aka a producer)?
 */
export async function _initClient(
  clientType,
  clientConfig,
  joinConfig = {},
  returnExisting = false,
  _isHost = false, // TODO[@phil]: cleanup
  clientRoleOptionsLevel = AgoraRTC.AudienceLatencyLevelType
    .AUDIENCE_LEVEL_ULTRA_LOW_LATENCY,
  onBeforeJoin = (_client) => {}
) {
  const {
    videoEncryption: videoEncryptionEnabled,
    eagerEnableAgoraProxy: eagerEnableAgoraProxyEnabled,
  } = getFeatureFlags();

  const { agoraConfig, videoConfig, localClients, agoraResolvedScheme } =
    store.getState().Video;
  const { registration } = store.getState().User;

  const existingClient = localClients[clientType];
  if (existingClient && existingClient.client) {
    if (returnExisting) {
      return existingClient.client;
    }
    throw new Error(`Client of type ${clientType} already exists`);
  }

  const clientTypeNonNull = clientType || CLIENT_TYPE.PRIMARY;
  const joinToken =
    joinConfig.token ||
    agoraConfig.tokens[getTokenKeyForClientType(clientTypeNonNull)];
  const joinChannel = joinConfig.channel || agoraConfig.channel;
  const streamId = getStreamIdFromUserId(registration.id, clientTypeNonNull);

  const client = await WelcomeAV.createClient({
    appId: agoraConfig.appId,
    clientId: streamId,
    channel: joinChannel,
    token: joinToken,
    channelMode: clientConfig?.mode ?? videoConfig.mode,
    encryptionSecret: videoEncryptionEnabled
      ? joinConfig.encryptionSecret || agoraConfig.encryptionSecret
      : undefined,
    eagerUseProxy: isUsingProxy || eagerEnableAgoraProxyEnabled,
    shouldLocalClientsSubscribe:
      joinConfig.shouldLocalClientsSubscribe || false,
    onBeforeJoin,
    agoraResolvedScheme,
    clientRoleOptionsLevel,
  });

  if (!isUsingProxy) {
    isUsingProxy = client.isUsingProxy;
  }

  store.dispatch(actions.setClient(clientTypeNonNull, client));
  return client;
}

async function _removeStream(clientType) {
  const clientData = store.getState().Video.localClients[clientType];
  if (!clientData) {
    return;
  }

  const { client, stream } = clientData;
  if (!client || !stream) {
    return;
  }

  stream.stop(/* stopTracks = */ true);
  await client.unpublish(stream);
  store.dispatch(actions.removeLocalStream(clientType));
  _removeStreamById(stream.getId());
}

export async function _setStream(stream, client, clientType) {
  // If we're in the green room, check if we've hit the max streams; if so, stop the stream's tracks and throw
  if (clientType === CLIENT_TYPE.PRIMARY || clientType === CLIENT_TYPE.SCREEN) {
    const numGreenRoomStreams = getNumGreenRoomStreams(store.getState());
    if (numGreenRoomStreams >= MAX_GREEN_ROOM_STREAMS) {
      if (stream.getVideoTrack()) {
        stream.getVideoTrack().stop();
      }
      if (stream.getAudioTrack()) {
        stream.getAudioTrack().stop();
      }
      throw new Error(ERRORS.STREAM_LIMIT_REACHED);
    }
  }

  return client.publish(stream);
}

/**
 * Sets the stream of the client. If a stream already exists for that client, it will replace its tracks
 * and return that existing stream. Otherwise, it will create & return a new stream
 *
 * @param {number} clientType Client type to set the stream for
 * @param {Object} config Stream config parameters; same as Agora's StreamSpec object
 * @param {Object} options Options for stream configuration. Supports "removeVideo" to remove the video track, "removeAudio"
 * to remove the audio track, "videoConfiguration" to set a different video config
 * @returns The stream that was set
 */
export async function _setStreamForClient(clientType, config, options = {}) {
  const { localClients } = store.getState().Video;
  if (!localClients[clientType] || !localClients[clientType].client) {
    throw new Error(
      "We couldn’t access the selected webcam. Please make sure no other application is currently using it"
    );
  }

  // We publish new streams to the green room from here, and currently ONLY from here. If this isn't a breakout,
  // check if the green room is full. Quite hacky... but also effective. There are a ton of corner cases, and
  // this is (I'm thinking) the safest way to go about enforcing this limit universally.
  if (clientType !== CLIENT_TYPE.SECONDARY && maxGreenRoomStreamsReached()) {
    throw new Error("The green room is full.");
  }

  const performChecks = (_stream) => {
    if (options.removeVideo) {
      _stream.removeTrack(_stream.getVideoTrack());
    }
    if (options.removeAudio) {
      _stream.removeTrack(_stream.getAudioTrack());
    }
    if (_stream.getVideoTrack() == null && _stream.getAudioTrack() == null) {
      throw new Error(ERRORS.NO_TRACKS);
    }
  };

  const { client, stream: existingStream } = localClients[clientType];

  const videoConfiguration =
    options.videoConfiguration ||
    getVideoConfigurationForClientType(clientType);

  // Need to change devices differently for Agora 4.x
  if (existingStream) {
    clientType != CLIENT_TYPE.SCREEN &&
      (await applyVirtualBackgroundToRCStream(
        existingStream,
        config.virtualBackgroundType
      ));
    if (config.cameraId && !existingStream.getAgoraVideoTrack()) {
      await client.publishAndAddVideoTrackToStream(
        existingStream,
        clientType === CLIENT_TYPE.SECONDARY
          ? { mirror: true, ...config }
          : config || {},
        videoConfiguration
      );
      emitter.emit("streamMuteChanged");
    } else if (!config.cameraId && existingStream.getAgoraVideoTrack()) {
      await client.unpublishAndRemoveVideoTrackFromStream(existingStream);
      emitter.emit("streamMuteChanged");
    }
    return existingStream.setCameraAndMicrophone(
      config.cameraId,
      config.microphoneId
    );
  }

  // If the stream already exists, replace the tracks
  const newStream = await _createStream(
    clientType,
    clientType === CLIENT_TYPE.SECONDARY
      ? { mirror: true, ...config }
      : config || {},
    videoConfiguration
  );

  performChecks(newStream);

  clientType != CLIENT_TYPE.SCREEN &&
    (await applyVirtualBackgroundToRCStream(
      newStream,
      config.virtualBackgroundType
    ));
  await _setStream(newStream, client, clientType);
  await store.dispatch(actions.setStream(clientType, newStream));
  return newStream;
}

function subscribeToSnapshot(snapshot, oldSnapshot) {
  const {
    Video: {
      streams,
      localClients: { [CLIENT_TYPE.PRIMARY]: primaryStageData = {} },
      stageSubLevel,
    },
  } = store.getState();

  const { client } = primaryStageData;

  // If stage sub level is ALL or NONE, you're not subscribing based
  // on the stage snapshot. Only applies to STAGE_ONLY.
  if (
    !client ||
    stageSubLevel === StageSubLevel.ALL ||
    stageSubLevel === StageSubLevel.NONE
  ) {
    return;
  }

  // Sub/unsub from on-stage streams
  const { onStageStreams: newStreamIds } = snapshot;

  const unsubList = oldSnapshot
    ? oldSnapshot.onStageStreams.filter((s) => newStreamIds.indexOf(s) < 0)
    : [];

  const subList = oldSnapshot
    ? newStreamIds.filter((s) => oldSnapshot.onStageStreams.indexOf(s) < 0)
    : [...newStreamIds];

  const oldSnapshotNotNull = oldSnapshot || {};

  // Stage screenshare
  const hasNewStageScreenshare =
    oldSnapshotNotNull.stageScreenshareStreamId !==
    snapshot.stageScreenshareStreamId;

  if (snapshot.stageScreenshareStreamId && hasNewStageScreenshare) {
    subList.push(snapshot.stageScreenshareStreamId);
  }

  if (oldSnapshotNotNull.stageScreenshareStreamId && hasNewStageScreenshare) {
    unsubList.push(oldSnapshotNotNull.stageScreenshareStreamId);
  }

  unsubList
    .filter((x) => !x.local)
    .forEach((streamId) => {
      if (!streams[streamId]) {
        return;
      }
      client.unsubscribe(streams[streamId]);
    });

  subList
    .filter((x) => !x.local)
    .forEach((streamId) => {
      if (!streams[streamId]) {
        return;
      }
      subscribe(client, streams[streamId]);
    });
}

/**
 * Screensharing is only supported on chrome 72 or later
 * https://docs.agora.io/en/Video/screensharing_web?platform=Web#a-namechromeascreen-sharing-on-google-chrome
 */
export function checkBrowserCanScreenShare() {
  const browser = detect();
  if (browser.name === "chrome" && parseInt(browser.version, 10) >= 72) {
    return true;
  }

  if (browser.name === "firefox" && parseInt(browser.version, 10) >= 56) {
    return true;
  }

  if (browser.name === "edge-chromium") {
    return true;
  }

  return false;
}

export function getCurrentBrowser() {
  return detect();
}

/*
=======================================================================================================
PUBLIC METHODS
=======================================================================================================
*/

/**
 * Adjust the resolution we are sending based on whether we are on stage or in the green room, and who
 * else is on stage with us. If we are in the green room, we should send a low resolution stream. If we
 * are sharing the stage with others, send a medium resolution stream. If we are the only stream on stage,
 * send a high resolution stream. Unless we are in poor connection mode, in which case never send high stream.
 *
 * For screenshare, send high resolution stream if it's on stage, otherwise send low resolution stream
 */
export async function adjustStageResolution() {
  const {
    Video: { localClients },
  } = store.getState();

  const primaryStream = localClients[CLIENT_TYPE.PRIMARY]?.stream;

  if (primaryStream) {
    const config = getVideoConfigurationForClientType(CLIENT_TYPE.PRIMARY);
    setVideoEncoderConfigurationAndUpdateTracks(primaryStream, config);
  }

  const screenshareStream = localClients[CLIENT_TYPE.SCREEN]
    ? localClients[CLIENT_TYPE.SCREEN].stream
    : null;

  if (screenshareStream) {
    const configuration = getVideoConfigurationForClientType(
      CLIENT_TYPE.SCREEN
    );
    setVideoEncoderConfiguration(screenshareStream, configuration);
  }
}

// Combine the global (set by producer) injectStreamVolume with the local (set by each
// user) stageVolume to determine the correct volume for the injected audio/video stream
function calculateRtmpVolume(injectStreamVolume, stageVolume) {
  let masterVolume = injectStreamVolume;
  if (isNil(injectStreamVolume) || isNaN(injectStreamVolume)) {
    masterVolume = 100;
  }
  const volume = Math.floor((parseInt(masterVolume, 10) / 100) * stageVolume);

  if (isNil(volume) || isNaN(volume)) {
    return 100;
  }

  if (volume < 0) {
    return 0;
  }

  if (volume > 100) {
    return 100;
  }

  return volume;
}

function getGreenRoomVolumeKey(stage) {
  const { segmentedLocalStorageVolumeEnabled } = getFeatureFlags();
  return segmentedLocalStorageVolumeEnabled
    ? `${stage.hashid}:${GR_VOLUME_KEY}`
    : GR_VOLUME_KEY;
}

function getGreenRoomPremuteVolumeKey(stage) {
  const { segmentedLocalStorageVolumeEnabled } = getFeatureFlags();
  return segmentedLocalStorageVolumeEnabled
    ? `${stage.hashid}:${GR_PREMUTE_VOLUME_KEY}`
    : GR_PREMUTE_VOLUME_KEY;
}

function getStageVolumeKey(stage) {
  const { segmentedLocalStorageVolumeEnabled } = getFeatureFlags();
  return segmentedLocalStorageVolumeEnabled
    ? `${stage.hashid}:${STAGE_VOLUME_KEY}`
    : STAGE_VOLUME_KEY;
}

function getStagePremuteVolumeKey(stage) {
  const { segmentedLocalStorageVolumeEnabled } = getFeatureFlags();
  return segmentedLocalStorageVolumeEnabled
    ? `${stage.hashid}:${STAGE_PREMUTE_VOLUME_KEY}`
    : STAGE_PREMUTE_VOLUME_KEY;
}

export function getGreenRoomVolume() {
  const stage = store.getState().Stage;

  return parseInt(
    localStorage.getItem(getGreenRoomVolumeKey(stage)) || DEFAULT_GR_VOLUME,
    10
  );
}

function setCachedGreenRoomVolume(volume) {
  const stage = store.getState().Stage;
  localStorage.setItem(getGreenRoomVolumeKey(stage), volume);
}

export function setGreenRoomVolume(
  volume,
  setPreMute = false,
  doNotSetPreMuteToZero = false
) {
  const {
    Video: { streams, stageSnapshot },
    Stage: stage,
  } = store.getState();

  Object.values(streams)
    .filter((stream) => !isOnStage(stream, stageSnapshot))
    .forEach((stream) => {
      stream.setAudioVolume(volume);
    });

  if (setPreMute) {
    const preMuteVolume = getGreenRoomVolume();
    if (!(doNotSetPreMuteToZero && preMuteVolume === 0)) {
      localStorage.setItem(getGreenRoomPremuteVolumeKey(stage), preMuteVolume);
    }
  }

  setCachedGreenRoomVolume(volume);
}

export function getPreMuteGreenRoomVolume() {
  const stage = store.getState().Stage;

  return parseInt(
    localStorage.getItem(getGreenRoomPremuteVolumeKey(stage)) ||
      DEFAULT_GR_VOLUME,
    10
  );
}

function setCachedStageVolume(volume) {
  const stage = store.getState().Stage;
  localStorage.setItem(getStageVolumeKey(stage), volume);
}

export function getPreMuteStageVolume() {
  const stage = store.getState().Stage;

  return parseInt(
    localStorage.getItem(getStagePremuteVolumeKey(stage)) ||
      DEFAULT_STAGE_VOLUME,
    10
  );
}

/**
 * Gets the volumes for streams on the stage. Returns two values: normal stream
 * volume, and injected RTMP stream volume.
 *
 * @param {*} stageSnapshot Stage snapshot to get the injectStreamVolume. Only
 * need to pass this if you intend to use the RTMP stream volume.
 * @returns An array of [stageVolume, rtmpVolume]
 */
export function getStageVolumes(stageSnapshot) {
  const stage = store.getState().Stage;

  const stageVolume = parseInt(
    localStorage.getItem(getStageVolumeKey(stage)) || DEFAULT_STAGE_VOLUME,
    10
  );

  if (!stageSnapshot || isNil(stageSnapshot.injectStreamVolume)) {
    return [stageVolume, stageVolume];
  }

  return [
    stageVolume,
    calculateRtmpVolume(stageSnapshot.injectStreamVolume, stageVolume),
  ];
}

export function getStageVolume() {
  return getStageVolumes()[0];
}

export function updateStreamVolumes() {
  const {
    Video: { streams, stageSnapshot },
  } = store.getState();

  const [stageVolume, rtmpVolume] = getStageVolumes(stageSnapshot);
  const grVolume = getGreenRoomVolume();
  Object.values(streams).forEach((stream) => {
    if (isOnStage(stream, stageSnapshot)) {
      const isRtmp = stream.getId() === RTMP_STREAM_KEY;
      stream.setAudioVolume(isRtmp ? rtmpVolume : stageVolume);
    } else {
      stream.setAudioVolume(grVolume);
    }
  });
}

export function setStageVolume(volume, setPreMute = false) {
  const {
    Video: { streams, stageSnapshot },
    Stage: stage,
  } = store.getState();

  const rtmpVolume = calculateRtmpVolume(
    stageSnapshot.injectStreamVolume,
    volume
  );

  Object.values(streams)
    .filter((stream) => isOnStage(stream, stageSnapshot))
    .forEach((stream) => {
      const isRtmp = stream.getId() === RTMP_STREAM_KEY;
      stream.setAudioVolume(isRtmp ? rtmpVolume : volume);
    });

  if (setPreMute) {
    localStorage.setItem(getStagePremuteVolumeKey(stage), getStageVolumes()[0]);
  }

  setCachedStageVolume(volume);
}

/**
 * Gets video stats for all streams. Returns a mapping of stream ID to stats
 */
export async function getVideoStats() {
  const { streams: stageStreams } = store.getState().Video;
  const { VideoCallStreams } = store.getState();
  const { localClients } = store.getState().Video;
  const combinedStreams = {
    ...stageStreams,
    ...VideoCallStreams,
  };
  const sortedStreams = Object.values(combinedStreams).sort(
    (a, b) => a.getId() < b.getId()
  );

  const remoteVideoStatsMap = {};
  const localVideoStatsMap = {};
  const remoteAudioStatsMap = {};
  const localAudioStatsMap = {};

  Object.values(localClients).forEach((localClient) => {
    localClient.client.getRemoteVideoStats((statsMap) => {
      Object.keys(statsMap).forEach((uid) => {
        remoteVideoStatsMap[uid] = statsMap[uid];
      });
    });
    localClient.client.getLocalVideoStats((statsMap) => {
      Object.keys(statsMap).forEach((uid) => {
        localVideoStatsMap[uid] = statsMap[uid];
      });
    });
    localClient.client.getRemoteAudioStats((statsMap) => {
      Object.keys(statsMap).forEach((uid) => {
        remoteAudioStatsMap[uid] = statsMap[uid];
      });
    });
    localClient.client.getLocalAudioStats((statsMap) => {
      Object.keys(statsMap).forEach((uid) => {
        localAudioStatsMap[uid] = statsMap[uid];
      });
    });
  });

  return sortedStreams
    .map((stream) => {
      const local = isLocal(stream);
      const videoStatsMap = local
        ? localVideoStatsMap[stream.getId()]
        : remoteVideoStatsMap[stream.getId()];
      const audioStatsMap = local
        ? localAudioStatsMap[stream.getId()]
        : remoteAudioStatsMap[stream.getId()];
      if (videoStatsMap || audioStatsMap) {
        return {
          streamStats: {
            ...videoStatsMap,
            ...audioStatsMap,
          },
          videoStats: videoStatsMap,
          audioStats: audioStatsMap,
          isLocal: local,
          streamId: stream.getId(),
        };
      }
      return null;
    })
    .filter((x) => x != null);
}

export async function removeClient(clientType) {
  const clientData = store.getState().Video.localClients[clientType];
  if (!clientData) {
    return;
  }

  const { client, stream } = clientData;
  if (!client) {
    return;
  }

  stream?.stop(/* stopStreamTracks */ true);
  if (clientType === CLIENT_TYPE.PRIMARY) {
    store.dispatch(actions.removeAllStreams());
  }

  await client.leave();
  store.dispatch(actions.removeClient(clientType));
  if (stream) {
    _removeStreamById(stream.getId());

    // "user-left" / "stream-removed"
    // will not be reported for local streams
    _removeVideoCallStreamById(stream.getId());
  }
  adjustStageResolution();
}

/**
 * Subscribes/unsubscribes from the appropriate streams, then updates Redux. Listeners on the RTCClient will
 * read this value from the Redux store to determine which streams are subscribed to / unsubscribed from as
 * new streams are added / removed.
 *
 * @param {EventSubLevel} subLevel The event subscription level
 */
export function setStageSubLevel(subLevel) {
  const {
    stageSubLevel: oldSubLevel,
    streams,
    stageSnapshot,
    localClients: { [CLIENT_TYPE.PRIMARY]: primaryClient },
  } = store.getState().Video;
  WLog.log(
    "debug",
    "video",
    `Setting stage sub level to ${subLevel} (was ${oldSubLevel})`
  );
  if (subLevel === oldSubLevel) {
    return null;
  }

  // If there's no client, we can't subscribe to anything so just update redux
  if (!primaryClient || !primaryClient.client) {
    return store.dispatch(actions.setStageSubLevel(subLevel));
  }

  const { client } = primaryClient;
  const subscribeToStage =
    oldSubLevel === StageSubLevel.NONE && subLevel !== StageSubLevel.NONE;
  const subscribeToGreenRoom =
    oldSubLevel !== StageSubLevel.ALL && subLevel === StageSubLevel.ALL;
  const unsubFromGreenRoom =
    oldSubLevel === StageSubLevel.ALL && subLevel !== StageSubLevel.ALL;
  const unsubFromStage = subLevel === StageSubLevel.NONE;

  Object.values(streams).forEach((stream) => {
    if (stream.local) {
      return;
    }

    const onStage = isOnStage(stream, stageSnapshot);
    if (subscribeToStage && onStage) {
      subscribe(client, stream);
    }

    if (subscribeToGreenRoom && !onStage) {
      subscribe(client, stream);
    }

    if (unsubFromGreenRoom && !onStage) {
      client.unsubscribe(stream);
    }

    if (unsubFromStage && onStage) {
      client.unsubscribe(stream);
    }
  });

  return store.dispatch(actions.setStageSubLevel(subLevel));
}

/**
 * Gets a test stream for the user to do mic/camera checks before they go live. This will not be published.
 * @param {Object} devices Devices to use. Takes keys { microphoneId, cameraId }
 */
export async function getTestStream(devices = {}) {
  const micDevice = devices.microphoneId
    ? { deviceId: { exact: devices.microphoneId } }
    : true;
  const camDevice = devices.cameraId
    ? { deviceId: { exact: devices.cameraId } }
    : true;

  navigator.mediaDevices.getUserMedia =
    navigator.mediaDevices.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia;

  try {
    return navigator.mediaDevices.getUserMedia({
      audio: micDevice,
      video:
        devices.video === false
          ? false
          : {
              width: 1280,
              height: 720,
              ...camDevice,
            },
    });
  } catch (error) {
    if (error.name === "OverconstrainedError") {
      return navigator.mediaDevices.getUserMedia({
        audio: micDevice,
        video: devices.video === false ? false : camDevice,
      });
    }

    console.error(error);
    throw error;
  }
}

export async function streamHardwareToClient(clientType, options) {
  const preferredDevices = getPreferredAv();

  return _setStreamForClient(clientType, preferredDevices, options);
}

/*
 * @param {String} channel Agora channel
 * @param {String} token Agora token
 * @param {Function} onBeforeInitStream callback to run before initializing stream
 * @param {String} encryptionSecret encryption secret. This is the last arg for backwards-compatibility reasons. TODO: make this arg
 * @param {Boolean} streamHardware if set to true, the users hardware (camera & mic) will be streamed, if false, they will just join as a spectator of sorts
 * @param {Boolean} leaveAuditorium Whether or not to leave the stage channel when
 * joining this video call
 * 3 when removing videoEncryption feature flag
 */
export async function joinVideoCall(
  channel,
  token,
  onBeforeInitStream,
  encryptionSecret,
  streamHardware = true,
  leaveAuditorium = true,
  channelMode = "rtc"
) {
  WLog.log("debug", "video.calling", `Joining video call channel ${channel}`);
  const { videoEncryption: videoEncryptionEnabled } = getFeatureFlags();

  const joinConfig = {
    channel,
    token,
  };

  if (videoEncryptionEnabled) {
    joinConfig.encryptionSecret = encryptionSecret;
  }

  const client = await _initClient(
    CLIENT_TYPE.SECONDARY,
    {
      mode: channelMode,
    },
    joinConfig,
    /* returnExisting */ false,
    /* isHost */ false,
    /* clientRoleOptionsLevel */ AgoraRTC.AudienceLatencyLevelType
      .AUDIENCE_LEVEL_ULTRA_LOW_LATENCY,
    /* onBeforeJoin */ (cli) => {
      cli.on("stream-published", ({ stream }) => {
        _addVideoCallStream(stream);
      });

      cli.on("stream-unpublished", ({ stream }) => {
        _removeVideoCallStreamById(stream.getId());
      });

      cli.on("stream-added", ({ stream }) => {
        subscribe(cli, stream);
      });

      cli.on("stream-removed", ({ stream }) => {
        _removeVideoCallStreamById(stream.getId());
      });

      cli.on("peer-leave", ({ uid }) => {
        _removeVideoCallStreamById(uid);
      });

      cli.on("stream-subscribed", ({ stream }) => {
        stream.setAudioVolume(DEFAULT_VIDEO_CALL_VOLUME);
        _addVideoCallStream(stream);

        // FIXME: This should not be coupled with the video stuff here
        const userId = getRegIdFromStreamId(stream.getId());
        fetchReg(userId);
      });

      cli.on("mute-audio", () => {
        emitter.emit("streamMuteChanged");
      });

      cli.on("unmute-audio", () => {
        emitter.emit("streamMuteChanged");
        updateStreamVolumes();
      });

      cli.on("mute-video", () => {
        emitter.emit("streamMuteChanged");
      });

      cli.on("unmute-video", () => {
        emitter.emit("streamMuteChanged");
      });
    }
  );

  if (onBeforeInitStream) {
    onBeforeInitStream(client);
  }

  if (streamHardware) {
    await streamHardwareToClient(CLIENT_TYPE.SECONDARY);
  }

  /**
   * [Phil] This is a fix for ch4775. This is not the ideal place for this
   * logic, but as a quick hack to get a fix out, leaving the primary client
   * here & rejoining on leaveVideoCall works as a catch-all solution to
   * prevent this issue from happening
   */
  if (leaveAuditorium) {
    removeClient(CLIENT_TYPE.PRIMARY);
  }

  adjustVideoCallResolution();
}

export async function joinBreakout() {
  const { BreakoutConfig: config } = store.getState();
  const { agoraChannel, agoraRtcToken, agoraEncryptionSecret } = config;

  return joinVideoCall(
    agoraChannel,
    agoraRtcToken,
    null,
    agoraEncryptionSecret
  );
}

export function updateAgoraConfig(agoraConfig) {
  return store.dispatch(actions.updateAgoraConfig(agoraConfig));
}

export function setAgoraResolvedScheme(agoraResolvedScheme) {
  return store.dispatch(actions.setAgoraResolvedScheme(agoraResolvedScheme));
}

/**
 * Removes the local stream. If it's not the primary stream, the client will be destroyed as well.
 *
 * @param {String} streamId The ID of the stream
 */
export async function removeLocalStream(streamId) {
  const { localClients } = store.getState().Video;
  const clientTypes = Object.keys(localClients);
  for (let i = 0; i < clientTypes.length; i += 1) {
    const clientType = parseInt(clientTypes[i], 10);
    const { stream } = localClients[clientTypes[i]];
    if (!stream || stream.getId() !== streamId) {
      continue;
    }

    if (clientType === CLIENT_TYPE.PRIMARY) {
      _removeStream(clientType);
    } else {
      removeClient(clientType);
    }

    adjustStageResolution();
    return;
  }
}

/**
 * @param {Boolean} exceptBreakout Setting this to true will remove all except
 * the breakout client.
 */
export async function removeAllLocalStreams(
  exceptBreakout = false,
  removeAll = false
) {
  const { localClients } = store.getState().Video;
  const localClientsCopy = { ...localClients };

  Object.keys(localClientsCopy).forEach((clientType) => {
    if (exceptBreakout && clientType === CLIENT_TYPE.SECONDARY) {
      return;
    }

    if (!removeAll && parseInt(clientType, 10) === CLIENT_TYPE.PRIMARY) {
      _removeStream(clientType);
    } else {
      removeClient(clientType);
    }
  });
}

function muteVideo(stream, muted) {
  if (muted) {
    stream.muteVideo();
  } else {
    stream.unmuteVideo();
  }
  emitter.emit("streamMuteChanged");
}

function muteAudio(stream, muted) {
  if (muted) {
    stream.muteAudio();
  } else {
    stream.unmuteAudio();
  }
  emitter.emit("streamMuteChanged");
}

export async function setLocalStreamAudioMuted(streamId, muted) {
  const stream = _getLocalStream(streamId);
  if (!stream) {
    return;
  }
  muteAudio(stream, muted);
}

export async function setLocalStreamVideoMuted(streamId, muted) {
  const stream = _getLocalStream(streamId);
  if (!stream) {
    return;
  }
  muteVideo(stream, muted);
}

export async function setClientAudioMuted(clientType, muted) {
  const { stream } = _getClient(clientType);

  if (!stream) {
    return;
  }

  muteAudio(stream, muted);
}

export async function setClientVideoMuted(clientType, muted) {
  const { stream } = _getClient(clientType);

  if (!stream) {
    return;
  }

  muteVideo(stream, muted);
}

export function on(evt, callback) {
  if (events.indexOf(evt) !== -1) {
    emitter.on(evt, callback);
  }
}

export function off(evt, callback) {
  emitter.off(evt, callback);
}

/**
 * @param {Object} devices An optional object with keys { microphoneId, cameraId } to specify the input devices for
 * the stream. If these are not passed, defaults will be used.
 */
export async function streamHardwareToPrimaryClient() {
  return streamHardwareToClient(CLIENT_TYPE.PRIMARY);
}

export async function removePrimaryClientStream() {
  return _removeStream(CLIENT_TYPE.PRIMARY);
}

export async function removeSecondaryClientStream() {
  return _removeStream(CLIENT_TYPE.SECONDARY);
}

export async function stopPrimaryClientScreenshare() {
  return removeClient(CLIENT_TYPE.SCREEN);
}

export async function stopVideoCallScreenshare() {
  return removeClient(CLIENT_TYPE.SECONDARY_SCREEN);
}

export async function streamScreenToVideoCall(
  channel,
  token,
  encryptionSecret
) {
  if (checkBrowserCanScreenShare()) {
    await _initClient(
      CLIENT_TYPE.SECONDARY_SCREEN,
      { mode: "rtc" },
      { channel, token, encryptionSecret },
      /* returnExisting */ true,
      /* isHost */ false,
      /* clientRoleOptionsLevel */ AgoraRTC.AudienceLatencyLevelType
        .AUDIENCE_LEVEL_ULTRA_LOW_LATENCY,
      /* onBeforeJoin */ (client) => {
        client.on("stream-published", ({ stream }) => {
          _addVideoCallStream(stream);
        });

        client.on("stream-unpublished", ({ stream }) => {
          _removeVideoCallStreamById(stream.getId());
        });
      }
    );

    try {
      const stream = await _setStreamForClient(
        CLIENT_TYPE.SECONDARY_SCREEN,
        {
          screen: true,
          video: false,
        },
        {
          videoConfiguration: VIDEO_CONFIGURATION.HIGH_SCREENSHARE,
        }
      );
      stream.getVideoTrack().onended = stopVideoCallScreenshare;
    } catch (error) {
      console.error(error);
      await stopVideoCallScreenshare();
      throw error;
    }
  } else {
    throw new Error(
      "Screenshare is not supported on this browser. Please try Chrome or Edge."
    );
  }
}

export async function streamPrimaryClientScreen() {
  if (checkBrowserCanScreenShare()) {
    await _initClient(
      CLIENT_TYPE.SCREEN,
      /* clientConfig */ {},
      /* joinConfig */ {},
      /* returnExisting */ true,
      /* isHost */ true,
      /* clientRoleOptionsLevel */ AgoraRTC.AudienceLatencyLevelType
        .AUDIENCE_LEVEL_ULTRA_LOW_LATENCY,
      /* onBeforeJoin */ (client) => {
        client.on("stream-published", ({ stream }) => {
          _addStream(stream);
          // Update resolution again... as a workaround
          adjustStageResolution();
        });

        client.on("stream-unpublished", ({ stream }) => {
          _removeStreamById(stream.getId());
          adjustStageResolution();
        });

        client.on("stream-reconnect-end", ({ uid, success }) => {
          // Update resolutions on reconnect, in case disconnect happened while stream
          // was in wrong resolution
          if (isLocalStreamId(uid) && success) {
            adjustStageResolution();
          }
        });
      }
    );

    try {
      const stream = await _setStreamForClient(
        CLIENT_TYPE.SCREEN,
        {
          screen: true,
          video: false,
        },
        {}
      );
      stream.getVideoTrack().onended = () => stopPrimaryClientScreenshare();
    } catch (error) {
      console.error(error);
      await stopPrimaryClientScreenshare();
      throw error;
    }
  } else {
    throw new Error(
      "Screenshare is not supported on this browser. Please try Chrome or Edge."
    );
  }
}

export function updateStreamSubscriptions(snapshot, rtmpRemoved = false) {
  // If the current user is an attendee, this is where stream subscribes/unsubscribes happen
  const { stageSnapshot: oldStageSnapshot, streams } = store.getState().Video;

  // Is there an on-stage RTMP?
  const hasRtmpVideoStream =
    !!streams[RTMP_STREAM_KEY] && !isVideoMuted(streams[RTMP_STREAM_KEY]);
  if (hasRtmpVideoStream) {
    const {
      localClients: {
        [CLIENT_TYPE.PRIMARY]: { client },
      },
      stageSubLevel,
    } = store.getState().Video;

    if (stageSubLevel === StageSubLevel.STAGE_ONLY) {
      // Unsubscribe from everything except the RTMP stream––IF it's video
      const allStreams = Object.values(streams);
      allStreams.forEach((stream) => {
        if (stream.getId() === RTMP_STREAM_KEY) {
          subscribe(client, stream);
        } else {
          client.unsubscribe(stream);
        }
      });
    } else if (stageSubLevel === StageSubLevel.ALL) {
      // N.B This solves a specific issue where if an attendee / speaker joins the green room while
      // someone is on stage they would not be able to see that person when coming off of stage.
      // This will tell the client to subscribe to everyone we have not subscribed to WHILE a video
      // is still playing. i.e subscribe to anyone behind a video if they're not already subscribed.
      const allStreams = Object.values(streams);
      allStreams.forEach((stream) => {
        if (!stream.isSubscribed()) {
          subscribe(client, stream);
        }
      });
    }
  }

  // Re-subscribe from all on-stage streams
  if (hasRtmpVideoStream) {
    return;
  }

  if (rtmpRemoved) {
    subscribeToSnapshot(oldStageSnapshot);
  } else if (snapshot) {
    subscribeToSnapshot(snapshot, oldStageSnapshot);
  }
}

export async function initPrimaryClient(
  isHost = false,
  clientRoleOptionsLevel = AgoraRTC.AudienceLatencyLevelType
    .AUDIENCE_LEVEL_ULTRA_LOW_LATENCY
) {
  return _initClient(
    CLIENT_TYPE.PRIMARY,
    /* clientConfig */ {},
    /* joinConfig */ {},
    /* returnExisting */ false,
    isHost,
    clientRoleOptionsLevel,
    /* onBeforeJoin */ (client) => {
      client.on("stream-published", ({ stream }) => {
        _addStream(stream);
        setTimeout(() => adjustStageResolution());
      });

      client.on("stream-unpublished", ({ stream }) => {
        _removeStreamById(stream.getId());
        adjustStageResolution();
      });

      client.on("stream-added", ({ stream }) => {
        const { stageSubLevel } = store.getState().Video;
        // possible to get a local stream here from screenshare
        if (isLocal(stream)) {
          return;
        }

        if (stream.getId() === RTMP_STREAM_KEY) {
          subscribe(client, stream);
          store.dispatch(actions.setIsInjectionStreaming(true));
        } else if (stageSubLevel === StageSubLevel.ALL) {
          subscribe(client, stream);
        } else if (stageSubLevel === StageSubLevel.STAGE_ONLY) {
          _addStream(stream);
          adjustStageResolution();

          // If this is on the stage, subscribe to it
          const {
            stageSnapshot: {
              onStageStreams,
              stageScreenshareStreamId,
              injectStreamEaAssetType,
            },
            streams,
          } = store.getState().Video;

          const isRtmpStreamPresent = !!Object.values(streams).find(
            (s) => s.getId() === RTMP_STREAM_KEY
          );
          if (isRtmpStreamPresent && injectStreamEaAssetType === "video") {
            return;
          }

          const streamId = stream.getId();
          if (
            onStageStreams.includes(streamId) ||
            stageScreenshareStreamId === streamId
          ) {
            subscribe(client, stream);
          }
        } else if (stageSubLevel === StageSubLevel.NONE) {
          _addStream(stream);
        }
      });

      client.on("stream-removed", ({ stream }) => {
        // We can get local screenshare streams here. Not handled by this client.
        if (isLocal(stream)) {
          return;
        }

        _removeStreamById(stream.getId());
        adjustStageResolution();

        if (stream.getId() === RTMP_STREAM_KEY) {
          updateStreamSubscriptions(null, /* rtmpRemoved = */ true);
        }
      });

      client.on("peer-leave", ({ uid }) => {
        if (isLocalStreamId(uid)) {
          return;
        }

        _removeStreamById(uid);
        adjustStageResolution();

        if (uid === RTMP_STREAM_KEY) {
          updateStreamSubscriptions(null, /* rtmpRemoved = */ true);
          store.dispatch(actions.setIsInjectionStreaming(false));
        }
      });

      client.on("stream-subscribed", ({ stream }) => {
        _addStream(stream);
        adjustStageResolution();
        const isRtmp = stream.getId() === RTMP_STREAM_KEY;

        const {
          Video: { stageSnapshot },
        } = store.getState();
        if (isOnStage(stream, stageSnapshot)) {
          const [stageVolume, stageRtmpVolume] = getStageVolumes(stageSnapshot);
          stream.setAudioVolume(isRtmp ? stageRtmpVolume : stageVolume);
        } else {
          stream.setAudioVolume(getGreenRoomVolume());
        }

        if (isRtmp) {
          updateStreamSubscriptions();
        }

        fetchReg(getRegIdFromStreamId(stream.getId())).catch(console.error);
        emitter.emit("stageStreamSubscribed");
      });

      client.on("mute-audio", () => {
        emitter.emit("streamMuteChanged");
      });

      client.on("unmute-audio", () => {
        emitter.emit("streamMuteChanged");
        updateStreamVolumes();
      });

      client.on("mute-video", () => {
        emitter.emit("streamMuteChanged");
      });

      client.on("unmute-video", () => {
        emitter.emit("streamMuteChanged");
      });

      client.on("streamInjectedStatus", (evt) => {
        if (evt.status !== 0 && evt.status !== 5) {
          window.flash_messages.flashError(
            "Error playing video. It may still be processing."
          );
        }
      });

      client.on("stream-reconnect-end", ({ uid, success }) => {
        // Update resolutions on reconnect, in case disconnect happened while stream
        // was in wrong resolution
        if (isLocalStreamId(uid) && success) {
          adjustStageResolution();
        }
      });
    }
  );
}

export async function leaveVideoCall() {
  WLog.log("debug", "video.calling", "Leaving video call");
  /**
   * [Phil] This is a fix for ch4775. This is not the ideal place for this
   * logic, but as a quick hack to get a fix out, leaving the primary client
   * on joinVideoCall & rejoining here works as a catch-all solution to
   * prevent this issue from happening
   */
  initPrimaryClient().catch((err) => {
    if (err.message === `Client of type 0 already exists`) {
      WLog.log(
        "debug",
        "video",
        "Swallowing error: client already exists in leaveVideoCall"
      );
      return;
    }

    WLog.log(
      "warn",
      "video",
      "Failed to rejoin primary client when leaving video call",
      err
    );
  });

  await Promise.all([
    removeClient(CLIENT_TYPE.SECONDARY),
    removeClient(CLIENT_TYPE.SECONDARY_SCREEN),
  ]);

  return store.dispatch(clearVideoCallStreams());
}

export function isUserStreaming() {
  const { localClients } = store.getState().Video;
  const data = Object.values(localClients);
  for (let i = 0; i < data.length; i += 1) {
    const clientData = data[i];
    if (clientData && clientData.stream) {
      return true;
    }
  }

  return false;
}

export async function stopStreamVideo(eventAssetId) {
  const stage = store.getState().Stage;
  return axios
    .delete(
      `/events/${stage.eventId}/stages/${stage.id}/video_playbacks/0?event_asset_id=${eventAssetId}`
    )
    .catch((err) => {
      WLog.log(
        "warn",
        "welcomeav.streaming.rtmp",
        "Error stopping the video",
        err
      );
      window.flash_messages.flashError(
        "There was an error stopping the video. Please try again."
      );
    });
}

export async function forceStopStreamVideo() {
  const stage = store.getState().Stage;
  return axios
    .delete(`/events/${stage.eventId}/stages/${stage.id}/video_playbacks/0`, {
      data: { forceStop: true },
    })
    .catch((err) => {
      WLog.log(
        "warn",
        "welcomeav.streaming.rtmp",
        "Error force-stopping the video",
        err
      );
      window.flash_messages.flashError(
        "There was an error stopping the video. Please try again."
      );
    });
}

export async function streamVideo(eventAssetId) {
  const stage = store.getState().Stage;
  return axios
    .post(`/events/${stage.eventId}/stages/${stage.id}/video_playbacks/`, {
      event_asset_id: eventAssetId,
    })
    .catch((err) => {
      WLog.log(
        "warn",
        "welcomeav.streaming.rtmp",
        "Error starting the video",
        err
      );
      window.flash_messages.flashError(
        "There was an error starting the video. Please try again."
      );
    });
}

export function isInVideoCall() {
  const clientData = store.getState().Video.localClients[CLIENT_TYPE.SECONDARY];
  return !!clientData && !!clientData.client;
}

export function addDebugGreenRoomStream() {
  if (!maxGreenRoomStreamsReached()) {
    const dummyStream = new DummyRCStream();
    _addStream(dummyStream);
  }
}
